import Icon from '../Icons/Icon';
import Link from 'next/link';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import style from './Text.module.scss';

const Text = ({ customClassName, text, children, as }) => {
  const className = 'c-Text';
  const baseClassNames = classNames(className, style[className], {
    [customClassName]: !!customClassName
  });

  const isArrText = Array.isArray(text);
  const ElementType = `${as}`;

  const renderArrayTitle = () => {
    return text.map((textData, key) => {
      const {
        text: strText,
        isHighlighted,
        isUnderlined,
        isBolder,
        isAnchorText,
        isNewLine,
        isNewParagraph,
        isSemiBold,
        isStrikeThrough,
        isItalic,
        isIcon,
        iconName,
        link,
        isPoint,
        customClassNames,
        ...rest
      } = textData;

      if (isIcon && iconName) {
        return (
          <span
            key={key}
            className={style[`${className}--icon`]}
            {...rest}>
            <Icon name={iconName} />
          </span>
        );
      }

      const spanClasses = classNames(
        {
          [`${className}--highlighted`]: isHighlighted,
          [style[`${className}--highlighted`]]: isHighlighted,
          [`${className}--semibold`]: isSemiBold,
          [style[`${className}--semibold`]]: isSemiBold,
          [`${className}--bolder`]: isBolder,
          [style[`${className}--bolder`]]: isBolder,
          [style[`${className}--anchor`]]: isAnchorText,
          [`${className}--anchor`]: isAnchorText,
          [`${className}--newline`]: isNewLine,
          [style[`${className}--newline`]]: isNewLine,
          [`${className}--new-paragraph`]: isNewParagraph,
          [style[`${className}--new-paragraph`]]: isNewParagraph,
          [style[`${className}--striked`]]: isStrikeThrough,
          [style[`${className}--underlined`]]: isUnderlined,
          [`${className}--link`]: link,
          [style[`${className}--link`]]: link,
          [customClassNames]: !!customClassNames
        },
        { italic: isItalic }
      );

      return isPoint ? (
        <li key={key} className={spanClasses}>
          <span className={spanClasses}>{strText}</span>
        </li>
      ) : (
        <span
          key={key}
          className={spanClasses}
          {...rest}
          data-testid={`${strText}-text`}>
          {link ? (
            <Link
              href={link}
              title={strText}
              style={{ textDecoration: 'none', textColor: '#00aff4' }}
              className={style[`${className}--link`]}>
              {strText}
            </Link>
          ) : (
            <>{strText}</>
          )}
        </span>
      );
    });
  };

  return (
    <ElementType
      className={baseClassNames}
      data-testid={isArrText ? '' : `${text}-text`}>
      {isArrText ? renderArrayTitle() : text ?? children}
    </ElementType>
  );
};

export const TextArrType = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    isHighlighted: PropTypes.bool,
    isBolder: PropTypes.bool,
    isSemiBold: PropTypes.bool,
    isAnchorText: PropTypes.bool,
    link: PropTypes.string
  })
);

export const TextType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.array,
  TextArrType
]);

Text.defaultProps = {
  as: 'p'
};

Text.propTypes = {
  text: TextType,
  // children: PropTypes.elements,
  customClassName: PropTypes.string,
  as: PropTypes.string
};

export default Text;
