import dynamic from 'next/dynamic';
import { PropTypes } from 'prop-types';
import React from 'react';

const AlumniIcon = dynamic(() => import('./AlumniIcon'));
const AnnouncementIcon = dynamic(() => import('./AnnouncementIcon'));
const BulbOnIcon = dynamic(() => import('./BulbOnIcon'));
const CrownIcon = dynamic(() => import('./CrownIcon'));
const EyeVisible = dynamic(() => import('./EyeVisible'));
const FlagIcon = dynamic(() => import('./FlagIcon'));
const FacebookDarkIcon = dynamic(() => import('./FacebookDarkIcon'));
const HeartIcon = dynamic(() => import('./HeartIcon'));
const GrabLogo = dynamic(() => import('./GrabLogo'));
const GrabXNasAcademyIcon = dynamic(() => import('./GrabXNasAcademyIcon'));
const GraduationCap = dynamic(() => import('./GraduationCap'));
const MediaIcon = dynamic(() => import('./MediaIcon'));
const MilestoneDotIcon = dynamic(() => import('./MilestoneDotIcon'));
const MXCreatorsIcon = dynamic(() => import('./MXCreatorsIcon'));
const MXIndividualsIcon = dynamic(() => import('./MXIndividualsIcon'));
const MXTakaTakIcon = dynamic(() => import('./MXTakaTakIcon'));
const MXTakaTakXNasAcademyIcon = dynamic(() =>
  import('./MXTakaTakXNasAcademyIcon')
);
const PlayIcon = dynamic(() => import('./PlayIcon'));
const PlayButtonsIcon = dynamic(() => import('./PlayButtonsIcon'));
const PopularHeart = dynamic(() => import('./PopularHeart'));
const StipendIcon = dynamic(() => import('./StipendIcon'));
const TouchIcon = dynamic(() => import('./TouchIcon/TouchIcon'));
const UserGroupIcon = dynamic(() => import('./UserGroupIcon'));
const VideoLibraryIcon = dynamic(() => import('./VideoLibraryIcon'));
const VideoMonitorIcon = dynamic(() => import('./VideoMonitorIcon'));
const WhatsappIconStroked = dynamic(() => import('./WhatsappIconStroked'));
const MessagingIcon = dynamic(() => import('./MessagingIcon'));
const UserDarkIcon = dynamic(() => import('./UserDarkIcon'));
const QADarkIcon = dynamic(() => import('./QADarkIcon'));
const DoubleCircleIcon = dynamic(() => import('./DoubleCircleIcon'));
const DollarIcon = dynamic(() => import('./DollarIcon'));
const TiktokDarkIcon = dynamic(() => import('./TiktokDarkIcon'));
const NAShortIcon = dynamic(() => import('./NAShortIcon'));
const LiveMonitorIcon = dynamic(() =>
  import('./LiveMonitorIcon/LiveMonitorIcon')
);
const FileIcon = dynamic(() => import('./FileIcon'));

const Icon = ({ name, ...props }) => {
  switch (name) {
    case 'bulb-on':
      return <BulbOnIcon {...props} />;
    case 'graduation-cap':
      return <GraduationCap {...props} />;
    case 'grab-logo':
      return <GrabLogo {...props} />;
    // case 'grab-for-good':
    //   return <GrabForGood {...props} />;
    case 'grabxnas-academy':
      return <GrabXNasAcademyIcon {...props} />;
    case 'play':
      return <PlayIcon {...props} />;
    case 'play-buttons':
      return <PlayButtonsIcon {...props} />;
    case 'messaging-dark':
    case 'message':
      return <MessagingIcon {...props} />;
    case 'mx-takatak':
      return <MXTakaTakIcon />;
    case 'mx-takatak-nas-academy':
      return <MXTakaTakXNasAcademyIcon />;
    case 'for-individuals-mx':
      return <MXIndividualsIcon />;
    case 'for-creators-mx':
      return <MXCreatorsIcon />;
    case 'user-group':
      return <UserGroupIcon />;
    case 'eye-visible':
      return <EyeVisible />;
    case 'facebook-dark':
      return <FacebookDarkIcon />;
    case 'tiktok-dark':
      return <TiktokDarkIcon />;
    case 'milestone-dot':
      return <MilestoneDotIcon />;
    case 'crown':
      return <CrownIcon />;
    case 'flag':
      return <FlagIcon />;
    case 'video-library':
      return <VideoLibraryIcon {...props} />;
    case 'video-monitor':
      return <VideoMonitorIcon {...props} />;
    case 'video-monitor-dark':
      return <VideoMonitorIcon fill="black" {...props} />;
    case 'live-monitor':
      return <LiveMonitorIcon {...props} />;
    case 'touch':
      return <TouchIcon />;
    case 'media':
      return <MediaIcon />;
    case 'alumni':
      return <AlumniIcon />;
    case 'stipend':
      return <StipendIcon />;
    case 'whatsapp-stroked':
      return <WhatsappIconStroked />;
    case 'user-dark':
      return <UserDarkIcon />;
    case 'qa-dark':
    case 'two-users':
      return <QADarkIcon {...props} />;
    case 'double-circle-icon':
      return <DoubleCircleIcon />;
    case 'dollar':
      return <DollarIcon />;
    case 'heart':
      return <HeartIcon />;
    case 'popular-tag-heart':
      return <PopularHeart {...props} />;
    case 'na-short-icon':
      return <NAShortIcon />;
    case 'announcement':
      return <AnnouncementIcon />;
    case 'file-icon':
      return <FileIcon />;
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
