import Image from 'next/image';
import PropTypes from 'prop-types';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

export const checkPropsValidity = ({
  mobileImgProps,
  tabletImgProps,
  desktopImgProps
}) => {
  const hasSrc =
    mobileImgProps?.src || tabletImgProps?.src || desktopImgProps?.src;

  return hasSrc;
};

/*
4 cases
- both mobile and desktop src and props are different
- both mobile and desktop src and props are same, just pass mobile props
- both mobile and desktop src are same, props different
- both mobile and desktop src are different, props same
*/

const NextImage = (props) => {
  const {
    alt,
    mobileImgProps,
    tabletImgProps,
    desktopImgProps,
    style,
    className,
    isDynamic
  } = props;

  const { isDesktopView, isTabletView } = useWindowWidthContext();

  const isValidProps = checkPropsValidity({
    mobileImgProps,
    tabletImgProps,
    desktopImgProps
  });

  const returnImageProps = () => {
    const m = mobileImgProps || {};
    const t = tabletImgProps || {};
    const d = desktopImgProps || {};

    // order of precedence: desktop → tablet → mobile (reverse desctructure)
    if (isDesktopView) return { ...m, ...t, ...d };
    else if (isTabletView) return { ...m, ...t };
    else return m;
  };

  const imageProps = returnImageProps();

  if (!mobileImgProps)
    throw new Error(
      `Missing Mobile Image Props and Desktop Image Props for ${alt}`
    );

  let loader;
  if (isDynamic) {
    loader = (imageProps) => {
      return imageProps.src;
    };
  }

  if (!isValidProps) {
    console.error('Invalid Props passed to NextImage ', props);
    return null;
  }

  return (
    <Image
      alt={alt}
      className={className}
      {...imageProps}
      style={{ ...style }}
      loader={loader}
    />
  );
};

export const NextImagePropTypes = {
  alt: PropTypes.string,
  mobileImgProps: PropTypes.object,
  tabletImgProps: PropTypes.object,
  desktopImgProps: PropTypes.object,
  isDynamic: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string
};

NextImage.propTypes = NextImagePropTypes;

export default NextImage;
